import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box2, Vector2 } from 'three';

import { iiafe } from '../../../../utilities/async';
import { isDefined } from '../../../js/utils/variables';
import { WmsSource } from './source';
import { BingMapsSource, BingMapWmsLayer } from './source/bing-map';
import { OpenStreetMapSource } from './source/open-street-map';
import { updateWmsAttributions } from './wms-attributions';

export enum MapLayers {
  Satellite = 'satellite',
  Road = 'road',
}

export interface WmsMapExtent {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export type OnWmsCanvasChangedData = { canvas: HTMLCanvasElement; extents: Box2 };
type OnWmsCanvasChangedCallback = (data: OnWmsCanvasChangedData | null) => void;

type Props = {
  mapLayer: MapLayers;
  mapExtent: WmsMapExtent | null;
  onCanvasSet?: OnWmsCanvasChangedCallback;
  projection?: string;
};

const sources: { [key in MapLayers]: WmsSource } = {
  [MapLayers.Satellite]: new BingMapsSource({ layer: BingMapWmsLayer.Satellite }),
  [MapLayers.Road]: new OpenStreetMapSource(),
};

export function useOpenLayerWmsMapRendering(
  mapTargetElementRef: RefObject<HTMLDivElement>,
  projection: string | undefined,
  mapLayer: MapLayers,
  mapExtent: WmsMapExtent | null,
  onCanvasChanged?: OnWmsCanvasChangedCallback,
) {
  const [olMap, setOlMap] = useState<Map | null>(null);

  // Ensure view is updated when projection is updated.
  const mapView = useMemo<View>(() => {
    return new View({
      center: [0, 0],
      zoom: 1,
      projection,
    });
  }, [projection]);

  const [mapSource, setMapSource] = useState<WmsSource>(sources[mapLayer]);

  useEffect(() => {
    setMapSource(sources[mapLayer]);
  }, [mapLayer, setMapSource]);

  useEffect(() => {
    // Create OpenLayers map so we can display it to the user.
    const map = new Map({
      layers: [],
      target: mapTargetElementRef.current || undefined,
      view: mapView,
    });

    map.on('error', () => {
      onCanvasChanged?.(null);
      updateWmsAttributions(null);
    });

    map.on('postrender', () => {
      const extents = map.getView().calculateExtent(map.getSize());
      const canvas = map?.getViewport().getElementsByTagName('canvas')[0] ?? null;

      onCanvasChanged?.({
        canvas,
        extents: new Box2(new Vector2(extents[0], extents[1]), new Vector2(extents[2], extents[3])),
      });

      updateWmsAttributions(map);
    });

    setOlMap(map);
    /* We set map target to "undefined", an empty string to represent a
     * nonexistent HTML element ID, when the React component is unmounted.
     * This prevents multiple maps being added to the map container on a
     * re-render.
     */
    return () => map?.setTarget(undefined);
  }, [mapView, onCanvasChanged, setOlMap, mapTargetElementRef]);

  useEffect(() => {
    if (!isDefined(olMap) || !isDefined(mapSource)) {
      return;
    }

    iiafe(async () => {
      // Clear all layers from the map and add the new layer.
      while (olMap.getAllLayers().length > 0) {
        olMap.removeLayer(olMap.getLayers().item(0));
      }

      await mapSource.initialize();
      olMap.addLayer(new TileLayer({ source: mapSource.getSource() }));
    });
  }, [mapSource, olMap]);

  useEffect(() => {
    if (!isDefined(mapExtent) || !isDefined(olMap)) {
      return;
    }

    const extents = [
      mapExtent.xmin < mapExtent.xmax ? mapExtent.xmin : mapExtent.xmax,
      mapExtent.ymin < mapExtent.ymax ? mapExtent.ymin : mapExtent.ymax,
      mapExtent.xmin < mapExtent.xmax ? mapExtent.xmax : mapExtent.xmin,
      mapExtent.ymin < mapExtent.ymax ? mapExtent.ymax : mapExtent.ymin,
    ];
    if (extents.some((extent) => isNaN(extent))) {
      return;
    }

    mapView?.fit(extents, {
      size: olMap.getSize(),
    });
  }, [mapExtent, mapView, olMap]);
}

const WmsMap = ({ mapExtent, onCanvasSet, mapLayer, projection }: Props) => {
  const mapTargetElementRef = useRef<HTMLDivElement>(null);

  useOpenLayerWmsMapRendering(mapTargetElementRef, projection, mapLayer, mapExtent, onCanvasSet);

  return (
    <Component>
      <OlCanvas ref={mapTargetElementRef} />
    </Component>
  );
};

const Component = styled.div`
  position: absolute;

  height: 2048px;
  width: 2048px;

  visibility: hidden;
`;

const OlCanvas = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
`;

export { WmsMap };
