import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'underscore';
import { v4 } from 'uuid';

import { ProjectModel } from '../../../../../typings/api/skymap/rest/v0/.common';
import { GeodataModel } from '../../../../../typings/api/skymap/rest/v1/.common';
import { iiafe } from '../../../../../utilities/async';
import { SubscriptionTopic } from '../../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { isDefined } from '../../../../js/utils/variables';
import { useErrorHandling } from '../../../hooks/use-error-handling';
import { useSubscribe } from '../../../hooks/use-subscribe';
import { Center } from '../../../styles/center';
import { reset } from '../../../utils/styled-reset';
import { GeodataListContent } from './geodata-list-content';
import { GeodataListItem } from './geodata-list-item';
import { NoGeodata } from './no-geodata';

interface Props {
  project: ProjectModel;
}

export const translatedProcessType = (processType: string) => {
  switch (processType) {
    case 'FULL_HIGH_QUALITY':
      return 'PREMIUM';
    case 'FULL':
      return 'STANDARD';
    case 'ORTHO_ONLY':
      return 'ORTOFOTO';
    case 'ORTHO_TIF':
      return 'ORTOFOTO FRÅN TIF';
    case 'POINTCLOUD_ONLY':
      return 'PUNKTMOLN';
  }
};

const sortByUploadedDesc = (a: GeodataModel, b: GeodataModel) =>
  new Date(a.uploaded) < new Date(b.uploaded) ? 1 : -1;

function useProjectGeodataQuery(projectId: string) {
  const { handleError, clearErrors, buildErrorList } = useErrorHandling();
  const [queryId] = useState(v4());

  const { data, refetch, isPending, isRefetching, error } = useQuery({
    queryKey: ['getProjectGeodata', projectId, queryId],
    queryFn: async () => {
      const response = await SkyMapAxiosServiceFactory.instance
        .createProjectServiceV1()
        .getProjectGeodata({ path: { projectId } });
      return response.data;
    },
    networkMode: 'always',
    retry: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  /**
   * Throttle so at most one refetch request is done every 2 seconds.
   */
  const debounceRefetch = useMemo(() => debounce(refetch, 2000), [refetch]);

  useSubscribe(SubscriptionTopic.WebsocketMessageRecieved, (event) => {
    if (
      (event.message === 'GeodataDeleted' ||
        event.message === 'GeodataCreated' ||
        event.message === 'GeodataUpdated') &&
      event.projectId === projectId
    ) {
      iiafe(async () => {
        await debounceRefetch();
      });
    }
  });

  // Define effect on error outside of mutation to avoid infinite render loop.
  useEffect(() => {
    if (isDefined(error)) {
      handleError(error, { retryCallback: refetch });
    } else {
      clearErrors();
    }
  }, [handleError, clearErrors, error, refetch]);

  const geodataList = useMemo(() => {
    return data?.sort(sortByUploadedDesc);
  }, [data]);

  const errorList = useMemo(() => buildErrorList(), [buildErrorList]);

  return {
    geodataList,
    errorList,
    isPending: isPending || isRefetching,
  };
}

const GeodataList = (props: Props) => {
  const [items, setItems] = React.useState<GeodataModel[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<GeodataModel>();
  const { geodataList, errorList } = useProjectGeodataQuery(props.project.id);

  const onDeleteDialogClosed = (wasDeleted: boolean) => {
    if (wasDeleted) {
      const newItems = [...items];
      newItems.splice(newItems.indexOf(selectedItem!), 1);

      setItems(newItems.sort(sortByUploadedDesc));
      setSelectedItem(undefined);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setSelectedItem(items.find((x) => x.id === selectedItem.id));
    }
  }, [items, selectedItem]);

  useEffect(() => {
    if (isDefined(geodataList)) {
      setItems(geodataList.slice());
    }
  }, [geodataList]);

  if (errorList) {
    return errorList;
  }

  if (!isDefined(geodataList)) {
    return <Center>Ett ögonblick...</Center>;
  }

  if (items.length === 0) {
    return <NoGeodata />;
  }

  return (
    <Component>
      <LeftSide>
        <ul>
          {items.map((item) => (
            <GeodataListItem
              item={item}
              key={item.id}
              selected={selectedItem?.id === item.id}
              onClick={() => setSelectedItem(item)}
              onDeleteDialogClosed={onDeleteDialogClosed}
            />
          ))}
        </ul>
      </LeftSide>

      <RightSide>
        <GeodataListContent items={items} selectedItem={selectedItem} />
      </RightSide>
    </Component>
  );
};

const Component = styled.div`
  ${reset}

  display: flex;
  flex: 1;
  gap: 2em;
  width: 100%;
  justify-content: space-evenly;
`;

const LeftSide = styled.div`
  width: 20em;
  height: 35em;
  overflow-y: auto;
`;

const RightSide = styled.div`
  flex: 1;
  height: 35em;
  overflow-y: auto;
`;

export { GeodataList };
