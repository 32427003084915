import { useMutation, useQuery } from '@tanstack/react-query';
import { TFunction } from 'i18next';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import _ from 'underscore';

import { FindProjectModel } from '../../../../typings/api/skymap/rest/v0/.common';
import { UserModel } from '../../../../typings/api/skymap/rest/v0/company';
import { AddedProjectUserModel } from '../../../../typings/api/skymap/rest/v0/project';
import { publish, SubscriptionTopic } from '../../../js/messaging/pubsub';
import { SkyMapAxiosServiceFactory } from '../../../js/services/axios/skymap-axios-service-factory';
import { ProjectStore } from '../../../js/stores/project-store';
import { UserStore } from '../../../js/stores/user-store';
import { emailRegex } from '../../../js/utils/text/validation';
import { isDefined } from '../../../js/utils/variables';
import { useDialog } from '../../hooks/use-dialog';
import { useErrorHandling } from '../../hooks/use-error-handling';
import { Button } from '../button/button';
import { DataTable, DataTableColumns, DataTableSortType } from '../data-table/data-table';
import { Dialog } from '../dialog/dialog';
import { DropdownSuggestion } from '../dropdown-suggestion/dropdown-suggesion';
import { Icon } from '../icon/icon';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { OverlayLoader } from '../overlay-loader/overlay-loader';
import { Stack } from '../stack/stack';
import { ProjectUserItem } from './user-list';

type ItemData = {
  id: string;
  name: string;
  type: 'project' | 'user' | 'user_without_email';
};

type Item = {
  removeIcon: ReactNode;
  id: string;
  name: string;
  type: 'project' | 'user' | 'user_without_email';
};

type UserData = { id: string; email?: string; firstName: string; lastName: string };
function getUserDisplayName(user: UserData) {
  const fullName = `${user.firstName} ${user.lastName}`;
  if (isDefined(user.email)) {
    return `${user.email} (${fullName})`;
  }

  return fullName;
}

function getEmailColumns(t: TFunction): DataTableColumns<Item> {
  return {
    name: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('email', { ns: 'common' }),
      alignment: 'left',
      unfilterable: true,
      shrink: true,
    },
    removeIcon: {
      alignment: 'right',
      unfilterable: true,
      width: '40px',
    },
  };
}

function getProjectColumns(t: TFunction): DataTableColumns<Item> {
  return {
    name: {
      sortableBy: { type: DataTableSortType.STRING },
      title: t('project', { ns: 'common' }),
      alignment: 'left',
      unfilterable: true,
      shrink: true,
    },
    removeIcon: {
      alignment: 'right',
      unfilterable: true,
      width: '40px',
    },
  };
}

const RemoveItemIcon = (
  id: string,
  dispatch: Dispatch<SetStateAction<Item[]>>,
  theme: DefaultTheme,
) => {
  return (
    <Icon
      color={theme.color.gray.dark}
      fixedWidth={true}
      icon={['fad', 'xmark']}
      onClick={() => dispatch((oldValue) => oldValue.filter((x) => x.id !== id))}
      onHoverStyle={{ icon: ['fas', 'xmark'] }}
    />
  );
};

type GetProjectsAndUsersResult = {
  usersAndProjects: ItemData[];
  projects: ItemData[];
};

async function getUsersAndProjects(
  isOrganizationAdmin: boolean,
  t: TFunction,
): Promise<GetProjectsAndUsersResult> {
  if (isOrganizationAdmin) {
    const usersResult = await SkyMapAxiosServiceFactory.instance
      .createUserServiceV0()
      .fetchAllUsers({});
    const projectsResult = await SkyMapAxiosServiceFactory.instance
      .createProjectServiceV0()
      .getBasicProjectInformationList();

    return {
      usersAndProjects: [
        ...usersResult.data
          .filter((x) => x.email.trim().length > 0) // Filter out unknown user.
          .map((user) => {
            return {
              type: 'user',
              id: user.email.toLowerCase(),
              name: getUserDisplayName(user),
            };
          }),
        ...projectsResult.data.map((project) => {
          return {
            type: 'project',
            id: project.id,
            name: `${project.name} (${t('userList.addProjectUsersDialog.numberOfAttendees', { ns: 'components', count: project.numberOfUsers })})`,
          };
        }),
      ] as ItemData[],
      projects: projectsResult.data.map((project) => {
        return {
          type: 'project',
          id: project.id,
          name: project.name,
        };
      }),
    };
  } else {
    const result = await SkyMapAxiosServiceFactory.instance.createProjectServiceV0().getAll({});
    const usersWithoutEmail: ItemData[] = result.data.flatMap((project) =>
      project.users.map((user) => {
        return {
          type: 'user_without_email',
          id: (user as UserData).id.toLowerCase(),
          name: getUserDisplayName(user as UserData),
        };
      }),
    );

    return {
      usersAndProjects: [
        ..._.uniq(usersWithoutEmail, (x) => x.id),
        ...result.data.map((project) => {
          return {
            type: 'project',
            id: project.id,
            name: `${project.name} (${t('userList.addProjectUsersDialog.numberOfAttendees', { ns: 'components', count: project.users.length })})`,
          };
        }),
      ] as ItemData[],
      projects: result.data.map((project) => {
        return {
          type: 'project',
          id: project.id,
          name: project.name,
        };
      }),
    };
  }
}

function useProjectsAndUsersQuery(onSuccess: (result: GetProjectsAndUsersResult) => void) {
  const isOrganizationAdmin = UserStore.instance.isOrganizationAdmin();
  const { buildErrorList, handleError, clearErrors } = useErrorHandling();
  const [queryId] = useState(UserStore.instance.user.id);
  const { t } = useTranslation();

  const { data, isPending, error } = useQuery({
    queryKey: ['getAddProjectUsersUserList', queryId],
    queryFn: () => getUsersAndProjects(isOrganizationAdmin, t),
    networkMode: 'always',
    retry: false,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Define effect on error outside of mutation to avoid infinite render loop.
  useEffect(() => {
    if (isDefined(error)) {
      handleError(error);
    } else {
      clearErrors();
    }
  }, [handleError, clearErrors, error]);

  useEffect(() => {
    if (isDefined(data)) {
      onSuccess(data);
    }
  }, [data, onSuccess]);

  return {
    isPendingProjectsAndUsersQuery: isPending,
    buildErrorListProjectsAndUsersQuery: buildErrorList,
  };
}

function useAddUsersFromProjectMutation(onSuccess: (project: FindProjectModel) => void) {
  const { clearErrors: clearApiErrors, handleError, buildErrorList } = useErrorHandling();

  const { mutate, isPending } = useMutation({
    mutationFn: (projectId: string) => {
      return SkyMapAxiosServiceFactory.instance.createProjectServiceV0().getById({
        query: { projectId },
      });
    },
    retry: false,
    networkMode: 'always',
    onSuccess: (response) => onSuccess(response.data),
    onMutate: () => clearApiErrors(),
    onError: (err) => handleError(err),
  });

  return {
    mutateGetUsersFromProject: mutate,
    buildGetUsersFromProjectsErrorList: buildErrorList,
    isPendingGetUsersFromProjects: isPending,
  };
}

function useAddUsersToProjectsMutation(onSuccess: (data: AddedProjectUserModel[]) => void) {
  const { clearErrors: clearApiErrors, handleError, buildErrorList } = useErrorHandling();

  const { mutate, isPending } = useMutation({
    mutationFn: async (body: { projectIds: string[]; emails: string[]; userIds: string[] }) => {
      return SkyMapAxiosServiceFactory.instance
        .createProjectServiceV0()
        .addUsersToProjects({ body });
    },
    retry: false,
    networkMode: 'always',
    onSuccess: (response) => onSuccess(response.data),
    onMutate: () => clearApiErrors(),
    onError: (err) => handleError(err),
  });

  return {
    mutateAddUsersToProjects: mutate,
    buildErrorListAddUsersToProjects: buildErrorList,
    isPendingAddUsersToProjects: isPending,
  };
}

const componentWidth = 600;
export const AddProjectUsersDialog = (props: {
  dialog: ReturnType<typeof useDialog>;
  onUsersAdded: (users: ProjectUserItem[]) => void;
}) => {
  const theme = useTheme();
  const [usersAndProjects, setUsersAndProjects] = useState<Item[]>([]);
  const [projects, setProjects] = useState<Item[]>([]);
  const [targetProjects, setTargetProjects] = useState<Item[]>([]);
  const [targetUsers, setTargetUsers] = useState<Item[]>([]);

  const onFetchProjectsAndUsers = useCallback(
    (result: GetProjectsAndUsersResult) => {
      setUsersAndProjects(
        result.usersAndProjects.map((item) => {
          return {
            type: item.type,
            name: item.name,
            id: item.id,
            removeIcon: RemoveItemIcon(item.id, setTargetUsers, theme),
          };
        }),
      );

      setProjects(
        result.projects.map((item) => {
          return {
            type: item.type,
            name: item.name,
            id: item.id,
            removeIcon: RemoveItemIcon(item.id, setTargetProjects, theme),
          };
        }),
      );

      const defaultProject = ProjectStore.instance.project!;
      setTargetProjects([
        {
          id: defaultProject.id,
          name: defaultProject.name,
          type: 'project',
          removeIcon: RemoveItemIcon(defaultProject.id, setTargetProjects, theme),
        },
      ]);
    },
    [theme, setUsersAndProjects, setProjects, setTargetUsers, setTargetProjects],
  );

  const { isPendingProjectsAndUsersQuery, buildErrorListProjectsAndUsersQuery } =
    useProjectsAndUsersQuery(onFetchProjectsAndUsers);

  const [multipleProjects, setMultipleProjects] = useState(false);
  const {
    mutateGetUsersFromProject,
    buildGetUsersFromProjectsErrorList,
    isPendingGetUsersFromProjects,
  } = useAddUsersFromProjectMutation((project) => {
    const items: Item[] = project.users
      .filter((newUser) => !targetUsers.some((existing) => existing.id === newUser.id))
      .map((user) => {
        return {
          type: 'user',
          id: (user as UserModel).email,
          name: getUserDisplayName(user as UserModel),
          removeIcon: RemoveItemIcon((user as UserModel).email, setTargetUsers, theme),
        };
      });

    setTargetUsers((oldValues) => {
      return [...oldValues, ...items];
    });
  });
  const {
    mutateAddUsersToProjects,
    buildErrorListAddUsersToProjects,
    isPendingAddUsersToProjects,
  } = useAddUsersToProjectsMutation((data) => {
    props.dialog.hide();

    props.onUsersAdded(
      data.map((x) => {
        return {
          email: x.email,
          id: x.userId ?? x.email,
          name: x.name ?? t('userList.addProjectUsersDialog.invitedUser', { ns: 'components' }),
          phoneNumber: x.phoneNumber ?? '',
          role: x.role === 'invited_user' ? 'invited_user' : 'project_attendee',
        };
      }),
    );

    if (targetProjects.length === 1) {
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'success',
        message: t('userList.addProjectUsersDialog.usersInvitedToProject', {
          ns: 'components',
          userCount: targetUsers.length,
        }),
      });
    } else {
      publish(SubscriptionTopic.ToastrMessage, {
        type: 'success',
        message: t('userList.addProjectUsersDialog.usersInvitedToProjects', {
          ns: 'components',
          userCount: targetUsers.length,
          projectCount: targetProjects.length,
        }),
      });
    }
  });

  const isPending =
    isPendingProjectsAndUsersQuery || isPendingGetUsersFromProjects || isPendingAddUsersToProjects;

  const { t } = useTranslation();
  const emailColumns = useMemo(() => getEmailColumns(t), [t]);
  const projectColumns = useMemo(() => getProjectColumns(t), [t]);

  return (
    <Dialog maxHeight="expand" onClose={props.dialog.hide}>
      {{
        header: t('userList.addProjectUsersDialog.title', { ns: 'components' }),
        content: (
          <OverlayLoader visible={isPending}>
            <Stack spacing={1}>
              <InfoBox color="yellow">
                <Stack spacing={0.5}>
                  {multipleProjects ? (
                    <>
                      <span>
                        {t('userList.addProjectUsersDialog.selectUsersAndProjectsInfo', {
                          ns: 'components',
                        })}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        <Trans
                          components={{
                            bold: <strong />,
                          }}
                          i18nKey="userList.addProjectUsersDialog.selectUsersInfo"
                          ns="components"
                          values={{
                            projectName: ProjectStore.instance.project!.name,
                          }}
                        />
                      </span>
                    </>
                  )}
                </Stack>
              </InfoBox>

              <SectionContainer spacing={0.5}>
                <LabelledContainer
                  text={t('userList.addProjectUsersDialog.addEmails', { ns: 'components' })}
                >
                  <DropdownSuggestion
                    clearOnSelection={true}
                    excludedSuggestions={targetUsers}
                    placeholder={t(
                      'userList.addProjectUsersDialog.assignEmailOrSelectUserPlacehold',
                      { ns: 'components' },
                    )}
                    suggestions={usersAndProjects}
                    width={componentWidth}
                    onAddInput={(input) => {
                      const value = input.toLocaleLowerCase();
                      const newUser: Item = usersAndProjects.find(
                        (x) => x.type === 'user' && x.id === value,
                      ) ?? {
                        type: 'user',
                        name: value,
                        id: value,
                        removeIcon: RemoveItemIcon(value, setTargetUsers, theme),
                      };

                      if (!targetUsers.some((x) => x.id === value)) {
                        setTargetUsers((oldValue) => [...oldValue, newUser]);
                      }
                    }}
                    onSuggestionSelected={(suggestion) => {
                      if (suggestion.type === 'project') {
                        mutateGetUsersFromProject(suggestion.id);
                      } else {
                        setTargetUsers((oldValue) => [...oldValue, suggestion]);
                      }
                    }}
                    onValidateInput={(value) => new RegExp(emailRegex).test(value)}
                  />
                </LabelledContainer>

                <ListContainer>
                  <DataTable
                    columns={emailColumns}
                    fixedLayout={true}
                    items={targetUsers}
                    pageSize={5}
                    tableStyle={{
                      rowCell: {
                        fontSize: '14px',
                      },
                      headerCell: {
                        fontSize: '14px',
                      },
                    }}
                  />
                </ListContainer>
              </SectionContainer>

              {multipleProjects && (
                <SectionContainer spacing={0.5}>
                  <LabelledContainer
                    text={t('userList.addProjectUsersDialog.addProjects', { ns: 'components' })}
                  >
                    <DropdownSuggestion
                      clearOnSelection={true}
                      excludedSuggestions={targetProjects}
                      placeholder={t('userList.addProjectUsersDialog.selectProjectPlaceholder', {
                        ns: 'components',
                      })}
                      suggestions={projects}
                      width={componentWidth}
                      onSuggestionSelected={(suggestion) => {
                        setTargetProjects((oldValue) => [...oldValue, suggestion]);
                      }}
                    />
                  </LabelledContainer>
                  <ListContainer>
                    <DataTable
                      columns={projectColumns}
                      fixedLayout={true}
                      items={targetProjects}
                      pageSize={5}
                      tableStyle={{
                        rowCell: {
                          fontSize: '14px',
                        },
                        headerCell: {
                          fontSize: '14px',
                        },
                      }}
                    />
                  </ListContainer>
                </SectionContainer>
              )}
            </Stack>
            {buildErrorListProjectsAndUsersQuery()}
            {buildErrorListAddUsersToProjects()}
            {buildGetUsersFromProjectsErrorList()}
          </OverlayLoader>
        ),
        footer: {
          left: (
            <Button
              color="secondary"
              disabled={multipleProjects}
              variant="contained"
              width="fit-content"
              onClick={() => setMultipleProjects(true)}
            >
              {t('userList.addProjectUsersDialog.addToMultipleProjects', { ns: 'components' })}
            </Button>
          ),
          right: (
            <>
              <Button
                color="primary"
                disabled={isPending}
                variant="contained"
                onClick={() =>
                  mutateAddUsersToProjects({
                    userIds: targetUsers
                      .filter((x) => x.type === 'user_without_email')
                      .map((x) => x.id),
                    emails: targetUsers.filter((x) => x.type === 'user').map((x) => x.id),
                    projectIds: targetProjects.map((x) => x.id),
                  })
                }
              >
                {t('confirm', { ns: 'common' })}
              </Button>

              <Button
                disabled={isPendingAddUsersToProjects}
                variant="text"
                onClick={props.dialog.hide}
              >
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

/**
 * Fixed height to prevent expanding when items are added.
 */
const SectionContainer = styled(Stack)`
  width: calc(${componentWidth}px + 1em); // Matches the width with the search box.
  padding: 0.5em;
`;

/**
 * Fixed height to prevent expanding when items are added.
 */
const ListContainer = styled.div`
  min-height: 240px; // Matches the DataTable component when it has more than 5 items.
  overflow: hidden;
`;
