import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
  DesignToolSeatModel,
  ProjectUserTagV1Model,
} from '../../../typings/api/skymap/rest/v0/.common';
import { UserByRequestResponse } from '../../../typings/api/skymap/rest/v0/user';
import { SkyMapAxiosServiceFactory } from '../../js/services/axios/skymap-axios-service-factory';
import { NavigationItem, UserStore } from '../../js/stores/user-store';
import state from '../../js/viewer/state';
import { getOneMomentText } from '../../locales/temp';
import { Button } from '../components/button/button';
import { useNonce } from '../hooks/use-nonce';
import { getSharedFolderId, isSharedViewerUrl } from '../routing/utils';
import { Center } from '../styles/center';
import { reset } from '../utils/styled-reset';

export interface CurrentUser {
  id: string;
  firstName: string;
  lastName: string;
  roles: UserRole[];
  selectedRole: UserRole;
  isAdmin: boolean;
  tags: ProjectUserTagV1Model[];
  designToolSeats: Pick<DesignToolSeatModel, 'companyId'>[];
}

export interface AppContext {
  currentUser?: CurrentUser;
  selectRole: (role: UserRole) => void;
  translateRole: (role: UserRole) => string;
  hasAdminRights: () => boolean;
  isMobileApplication: () => boolean;
}

export const AppContext = React.createContext<AppContext>(undefined!);

const saveNameItemToLocalStorage = (navItem: NavigationItem) =>
  localStorage.setItem('selectedRole', JSON.stringify(navItem));

const readNavItemFromLocalStorage = () => {
  const item = localStorage.getItem('selectedRole');

  if (!item) {
    return null;
  }

  try {
    return JSON.parse(item) as NavigationItem;
  } catch (err) {
    return null;
  }
};

export enum UserRole {
  ProjectUser = 'dashboard',
  CompanyAdmin = 'companyAdmin',
  OrganizationAdmin = 'organizationAdmin',
}

enum Status {
  Loading,
  Error,
  Loaded,
}

type Props = {
  children: React.ReactNode;
  displayLoading?: boolean;
};

const AppState = (props: Props) => {
  const [fetchedUserData, setFetchedUserData] = React.useState<UserByRequestResponse['data']>();
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | undefined>();
  const [status, setStatus] = React.useState<Status>(Status.Loading);
  const [refreshNonce, setRefreshNonce] = useNonce();

  React.useEffect(() => {
    const load = async () => {
      // If current URL is a shared folder URL, don't fetch user data.
      if (getSharedFolderId() || isSharedViewerUrl()) {
        setStatus(Status.Loaded);
        return;
      }

      try {
        setStatus(Status.Loading);
        const user = await SkyMapAxiosServiceFactory.instance
          .createUserServiceV0()
          .fetchRequestingUser({});

        setFetchedUserData(user.data);
        setStatus(Status.Loaded);
      } catch (err) {
        setStatus(Status.Error);
      }
    };

    void load();
  }, [refreshNonce]);

  const mapRole = React.useCallback((userRole: string) => {
    switch (userRole) {
      case 'dashboard':
        return UserRole.ProjectUser;
      case 'companyAdmin':
        return UserRole.CompanyAdmin;
      case 'organizationAdmin':
        return UserRole.OrganizationAdmin;
    }
  }, []);

  const getSelectedRole = React.useCallback((): UserRole => {
    let navItem = readNavItemFromLocalStorage();

    if (navItem) {
      return mapRole(navItem.id) as UserRole;
    }
    // If no selected role exists, take first role returned by getNavigationItems.
    // (Same as in UserStore)
    //
    // This is only for React Test Client (In SkyMap Portal selectedRole is set if local
    // storage if it doesn't exists, before any React components has been rendered).
    navItem = UserStore.getNavigationItems(fetchedUserData)[0];
    saveNameItemToLocalStorage(navItem);
    return mapRole(navItem.id) as UserRole;
  }, [fetchedUserData, mapRole]);

  const isMobileApplication = useCallback((): boolean => {
    return state.getValue('isNativeApp');
  }, []);

  const render = () => {
    switch (status) {
      case Status.Loading:
        return props.displayLoading ?? true ? <Center>{getOneMomentText()}</Center> : null;

      case Status.Loaded:
        return (
          <AppContext.Provider
            value={{
              currentUser: currentUser,
              selectRole: (role) => {
                const navItem = UserStore.getNavigationItems(fetchedUserData).find(
                  (x) => mapRole(x.id) === mapRole(role),
                )!;
                saveNameItemToLocalStorage(navItem);
                document.location.reload();
              },
              translateRole: (role) => {
                switch (role) {
                  case UserRole.OrganizationAdmin:
                    return 'Organisationsadmin';
                  case UserRole.CompanyAdmin:
                    return 'Projektadmin';
                  case UserRole.ProjectUser:
                    return 'Projektanvändare';
                }
              },
              hasAdminRights: () => {
                return currentUser?.isAdmin ?? false;
              },
              isMobileApplication,
            }}
          >
            {props.children}
          </AppContext.Provider>
        );

      case Status.Error:
        return (
          <Center>
            <Error>
              <>
                <h3>Oväntat fel</h3>
                <span>Det gick inte att hämta information om inloggad användare.</span>
              </>

              <Button color="primary" variant="contained" onClick={() => setRefreshNonce()}>
                Försök igen
              </Button>
            </Error>
          </Center>
        );
    }
  };

  React.useEffect(() => {
    if (!fetchedUserData) {
      return;
    }

    const selectedRole = getSelectedRole();
    const userRoles = UserStore.getNavigationItems(fetchedUserData)
      .map((x: NavigationItem) => x.id)
      .map(mapRole) as UserRole[];
    const isAdmin = [UserRole.OrganizationAdmin, UserRole.CompanyAdmin].includes(selectedRole);

    setCurrentUser({
      id: fetchedUserData.id,
      firstName: fetchedUserData.firstName,
      lastName: fetchedUserData.lastName,
      roles: userRoles,
      selectedRole,
      isAdmin,
      tags: fetchedUserData.projectUserTags,
      designToolSeats: fetchedUserData.designToolSeats ?? [],
    });
  }, [fetchedUserData, getSelectedRole, mapRole]);

  return render();
};

const Error = styled.div`
  ${reset}
  font-size: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
`;

export { AppState };
