import React from 'react';
import styled from 'styled-components';

// Container to center the line and text
const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

// Line styling
const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #ccc;
`;

// Text styling for the divider text
const DividerText = styled.span`
  padding: 0 10px;
  font-size: 14px;
  color: #666;
`;

type Props = {
  text?: string;
};

const VerticalDivider = (props: Props) => {
  return (
    <DividerContainer>
      <Line />
      {props.text && (
        <>
          <DividerText>{props.text}</DividerText>
          <Line />
        </>
      )}
    </DividerContainer>
  );
};

export { VerticalDivider };
