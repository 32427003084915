import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { iiafe } from '../../../../utilities/async';
import { FeatureFlagsManager } from '../../../js/core/feature-flags-manager';
import { ThemeManager } from '../../../js/theming/theme-manager';
import { SupportedLanguage } from '../../../js/utils/text/translation';
import { emailRegex } from '../../../js/utils/text/validation';
import { authHook } from '../../hooks/use-auth';
import { useLanguage } from '../../hooks/use-language';
import { useRouter } from '../../hooks/use-router';
import { reset } from '../../utils/styled-reset';
import { Button } from '../button/button';
import { InfoBox } from '../info-box/info-box';
import { LabelledContainer } from '../labelled-container/labelled-container';
import { Select } from '../select/select';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';
import { MicrosoftEntraIdSignInButton } from './sso/microsoft-entra-id/microsoft-entra-id-sign-in-button';
import { VerticalDivider } from './sso/vertical-divider';
import { useSignInQuery } from './use-sign-in-query';

export type Form = {
  language: string;
  email: string;
  password: string;
};

const SignInForm = () => {
  const { languageItems, selectLanguage, selectedLanguage, selectDefaultLanguage } = useLanguage();
  const { t } = useTranslation();
  const { saveToken } = authHook.useAuth();
  const { redirectToAngularState } = useRouter();

  // The login page need to set either the default or cookie language.
  // This should be handled in a overall Language state, but that will be part of the React conversion.
  React.useEffect(() => {
    void selectDefaultLanguage();
  }, [selectDefaultLanguage]);

  const {
    formState: { errors },
    control,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<Form>({
    defaultValues: {
      language: selectedLanguage.value,
      email: '',
      password: '',
    },
  });

  const {
    buildErrorList,
    excludeErrorPaths,
    getErrorForPath,
    hasError,
    mutation: signInMutation,
  } = useSignInQuery({
    onSuccess: async ({ data }) => {
      await saveToken(data.token);
      await redirectToAngularState('redirect');
      reset();
    },
  });

  React.useEffect(() => {
    setError('email', {
      type: 'custom',
      message: getErrorForPath('body.email'),
    });
    setError('password', {
      type: 'custom',
      message: getErrorForPath('body.password'),
    });
  }, [getErrorForPath, setError]);

  const excludeDefaultErrors = excludeErrorPaths(['body.email', 'body.password']);

  return (
    <Component>
      <h1>{t('signInForm.title', { ns: 'components' })}</h1>

      <form
        onSubmit={handleSubmit((form) => {
          signInMutation.mutate(form);
        })}
      >
        <Stack spacing={1}>
          <LabelledContainer text={t('signInForm.form.language', { ns: 'components' })}>
            {(formElementId) => {
              const id = formElementId();
              return (
                <Controller
                  control={control}
                  name="language"
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={signInMutation.isPending}
                      errorMessage={errors.language?.message}
                      id={id}
                      options={languageItems.map((x) => ({ id: x.value, name: x.text }))}
                      onChange={(e) => {
                        field.onChange(e);

                        iiafe(async () => {
                          await selectLanguage(e.target.value as SupportedLanguage);
                        });
                      }}
                    />
                  )}
                />
              );
            }}
          </LabelledContainer>

          <LabelledContainer
            required={true}
            text={t('signInForm.form.email', { ns: 'components' })}
          >
            {(formElementId) => (
              <TextBox
                {...register('email', {
                  required: true,

                  pattern: {
                    value: emailRegex,
                    message: t('signInForm.emailInvalidMessage', { ns: 'components' }),
                  },
                })}
                autoFocus={true}
                disabled={signInMutation.isPending}
                enforceCase="lowercase"
                errorMessage={errors.email?.message}
                id={formElementId()}
              />
            )}
          </LabelledContainer>

          <LabelledContainer
            required={true}
            text={t('signInForm.form.password', { ns: 'components' })}
          >
            {(formElementId) => {
              return (
                <TextBox
                  {...register('password', {
                    required: true,
                  })}
                  disabled={signInMutation.isPending}
                  errorMessage={errors.password?.message}
                  id={formElementId()}
                  type="password"
                />
              );
            }}
          </LabelledContainer>

          {hasError(excludeDefaultErrors) &&
            buildErrorList({
              filter: excludeDefaultErrors,
            })}

          {ThemeManager.instance.getThemeName() !== 'moba' && selectedLanguage.value !== 'sv' && (
            <InfoBox color="yellow" leftIcon={{ icon: ['fad', 'info'] }} topMargin={true}>
              Note that all features currently might not work as expected when you select another
              language than Swedish.
            </InfoBox>
          )}

          <Stack direction="row" justifyContent="space-between" margin="1em 0 0 0" spacing={0.25}>
            <Button
              color="secondary"
              disabled={signInMutation.isPending}
              type="button"
              variant="text"
              onClick={() => redirectToAngularState('requestpasswordchange')}
            >
              {t('signInForm.forgotPasswordButton', { ns: 'components' })}
            </Button>
            <Button
              color="primary"
              loading={signInMutation.isPending}
              type="submit"
              variant="contained"
            >
              {t('signInForm.loginButton', { ns: 'components' })}
            </Button>
          </Stack>

          {FeatureFlagsManager.instance.isEnabled('SK-5925-SSO') && (
            <>
              <VerticalDivider text={t('signInForm.ssoDivider', { ns: 'components' })} />

              <Stack direction="row" spacing={0.25}>
                <MicrosoftEntraIdSignInButton />
              </Stack>
            </>
          )}
        </Stack>
      </form>
    </Component>
  );
};

const Component = styled.div`
  ${reset}
  font-size: 14px;

  h1 {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 1em;
  }
`;

export { SignInForm };
