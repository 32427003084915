import { FloatingPortal } from '@floating-ui/react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { CSSProperties } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { useButtonHotkey } from '../../hooks/use-button-hotkey';
import { useElementWidth } from '../../hooks/use-element-width';
import { Button } from '../button/button';
import { Stack } from '../stack/stack';
import { TextBox } from '../text-box/text-box';

type Suggestion = {
  name: string;
  id: string;
};

const shownSuggesionsLimit = 5;

function filterSuggestions<T extends Suggestion>(
  name: string,
  suggestions: T[],
  excludedSuggestions?: T[],
  limit?: number,
) {
  return suggestions
    .filter(
      (suggestion) =>
        suggestion.name.toLowerCase().includes(name) &&
        !excludedSuggestions?.some((x) => x.id === suggestion.id),
    )
    .slice(0, limit);
}

interface Props<T extends Suggestion> {
  suggestions: T[];
  onValidateInput?: (input: string) => boolean;
  onAddInput?: (input: string) => void;
  excludedSuggestions?: T[];
  value?: string;
  onSuggestionSelected: (suggestion: T) => void;
  clearOnSelection?: boolean;
  width?: CSSProperties['width'];
  placeholder?: string;
  disabled?: boolean;
}

const DropdownSuggestion = <T extends Suggestion>({
  suggestions,
  excludedSuggestions,
  onSuggestionSelected,
  onValidateInput,
  onAddInput,
  disabled = false,
  clearOnSelection = false,
  width = '200px',
  value = undefined,
  placeholder = '',
}: Props<T>) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<T[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const { refs, strategy, update, floatingStyles } = useFloating({
    middleware: [offset(0), flip(), shift()],
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (isFocused && refs.reference.current && refs.floating.current) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [isFocused, refs.reference, refs.floating, update]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setIsFocused(true);
    setFilteredSuggestions(
      filterSuggestions(
        value.toLowerCase(),
        suggestions,
        excludedSuggestions,
        shownSuggesionsLimit,
      ),
    );
  };

  useEffect(() => {
    setIsInputValid(onValidateInput?.(inputValue) ?? true);
  }, [inputValue, onValidateInput]);

  const handleSuggestionClick = useCallback(
    (suggestion: T) => {
      onSuggestionSelected(suggestion);
      if (clearOnSelection) {
        setInputValue('');
      } else {
        setInputValue(suggestion.name);
      }
      setFilteredSuggestions([]);
      setIsFocused(false);
    },
    [clearOnSelection, onSuggestionSelected],
  );

  // Handles updating the textbox if the value is specified.
  useEffect(() => {
    if (isDefined(value)) {
      const suggestion = suggestions.find((x) => x.id === value);

      if (isDefined(suggestion)) {
        // If the box is focused we should automatically clear the content
        // so it's possible to search for a new item. Otherwise we auto select
        // the matching item.
        if (isFocused) {
          setInputValue('');
        } else {
          handleSuggestionClick(suggestion);
        }
        setFilteredSuggestions(
          filterSuggestions('', suggestions, excludedSuggestions, shownSuggesionsLimit),
        );
      } else {
        // If there is no matching suggestion to the value then we just clear the field.
        setInputValue('');
        setFilteredSuggestions([]);
        setIsFocused(false);
      }
    }
  }, [isFocused, value, handleSuggestionClick, suggestions, excludedSuggestions]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        !isFocused &&
        isDefined(refs.reference.current) &&
        (refs.reference.current as HTMLElement).contains(event.target as Node)
      ) {
        setIsFocused(true);
        setFilteredSuggestions(
          filterSuggestions(
            inputValue.toLowerCase(),
            suggestions,
            excludedSuggestions,
            shownSuggesionsLimit,
          ),
        );
      }

      if (
        isDefined(refs.floating.current) &&
        !refs.floating.current.contains(event.target as Node)
      ) {
        setIsFocused(false);
        setFilteredSuggestions([]);
      }
    },
    [isFocused, suggestions, excludedSuggestions, inputValue, refs.reference, refs.floating],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  const handleOnInputAdded = () => {
    if (!isDefined(onAddInput) || !isInputValid) {
      return;
    }

    onAddInput(inputValue);
    setInputValue('');
    setIsFocused(false);
    setFilteredSuggestions([]);
  };

  useButtonHotkey(confirmButtonRef, 'Enter');
  const { ref: componentRef, width: componentWidth } = useElementWidth();
  const allFilteredSuggestions = useMemo(
    () => filterSuggestions(inputValue.toLowerCase(), suggestions, excludedSuggestions, undefined),
    [inputValue, suggestions, excludedSuggestions],
  );

  const hiddenSuggestionsCount = allFilteredSuggestions.length - filteredSuggestions.length;

  return (
    <Component ref={componentRef} width={width}>
      <StackContainer direction="row" spacing={0.5} width={width}>
        <TextBox
          disabled={disabled}
          placeholder={placeholder}
          ref={refs.setReference}
          value={inputValue}
          onChange={handleInputChange}
        />
        {isDefined(onAddInput) && (
          <Button
            color="secondary"
            disabled={!isInputValid}
            leftIcon={{ icon: ['fad', 'plus'] }}
            noWrap={true}
            ref={confirmButtonRef}
            variant="contained"
            width="fit-content"
            onClick={handleOnInputAdded}
          >
            {t('add', { ns: 'common' })}
          </Button>
        )}
      </StackContainer>
      {isFocused && filteredSuggestions.length > 0 && (
        <FloatingPortal>
          <SuggestionsList
            className="notranslate"
            ref={refs.setFloating}
            style={{ ...floatingStyles, position: strategy }}
            width={componentWidth}
          >
            {filteredSuggestions.map((suggestion, index) => (
              <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion.name}
              </SuggestionItem>
            ))}
            {hiddenSuggestionsCount > 0 && (
              <SuggestionItem
                key={'-1'}
                onClick={() => {
                  if (refs.reference.current && refs.floating.current) {
                    setFilteredSuggestions(allFilteredSuggestions);
                    autoUpdate(refs.reference.current, refs.floating.current, update);
                  }
                }}
              >
                <HiddenOptionsSpan>
                  {t('dropdownSuggestion.showHiddenOptions', {
                    ns: 'components',
                    count: hiddenSuggestionsCount,
                  })}
                </HiddenOptionsSpan>
              </SuggestionItem>
            )}
          </SuggestionsList>
        </FloatingPortal>
      )}
    </Component>
  );
};

const StackContainer = styled(Stack)<{ width: CSSProperties['width'] }>`
  width: ${({ width }) => width};
`;

const Component = styled.div<{ width: CSSProperties['width'] }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

const SuggestionsList = styled.ul<{ width: number }>`
  margin: 0;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #888;
  overflow: hidden;
  overflow-y: auto;
  max-height: 350px;
  z-index: 1000;
  width: ${({ width }) => width}px; // Set the width directly on the list
  box-sizing: border-box; // Ensure borders/padding don’t affect the width
`;

const SuggestionItem = styled.li`
  padding: 0.5em;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.brand.light};
  }
`;

const HiddenOptionsSpan = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.color.gray.medium};
`;

export { DropdownSuggestion, Props as DropdownSuggestionProps, Suggestion };
