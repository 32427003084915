import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SubscriptionTopic } from '../../../js/messaging/pubsub';
import { UserStore } from '../../../js/stores/user-store';
import { ThemeManager } from '../../../js/theming/theme-manager';
import { permissionChecks } from '../../../js/utils/permission-check';
import { InputHandler } from '../../../js/viewer/input-handler';
import { ProjectGeodataManager } from '../../../js/viewer/project-geodata-manager';
import { SkyView } from '../../../js/viewer/skyview';
import { useSubscribe } from '../../hooks/use-subscribe';
import { Chip, ChipStyled } from '../chip/chip';
import { AsbuiltPanel } from '../skyview/panels/asbuilt/asbuilt-panel';
import { ShareView } from './components/share-view/share-view';

type Props = {
  skyView?: SkyView;
};

const SkyviewChipsContainer = (props: Props) => {
  const { t } = useTranslation();
  const [showShareSkyViewPanel, setShowShareSkyViewPanel] = React.useState(false);
  const [showAsbuiltPanel, setShowAsbuiltPanel] = React.useState(false);

  const userHasAccessToOnDemand = UserStore.instance.hasDesignToolAccessForCurrentProject();

  useSubscribe(SubscriptionTopic.SetTool, (toolName) => {
    setShowAsbuiltPanel(toolName === 'asbuilt-geometry');
  });

  const showShareSkyViewChip = ProjectGeodataManager.instance().hasTimelineGeodata();
  const showOnDemandChip = ThemeManager.instance.isSwedishDomain();
  const showAsbuiltChip = permissionChecks.hasAccessToFeature('feature:viewer:chips:as_built');

  return (
    <Component>
      {showShareSkyViewChip && (
        <Chip
          leftArea={{
            icon: {
              icon: 'share-nodes',
            },
          }}
          padding="medium"
          text={t('shareView.chipText', { ns: 'skyview' })}
          onClick={() => {
            setShowShareSkyViewPanel(!showShareSkyViewPanel);
          }}
        />
      )}

      {showOnDemandChip && (
        <Chip
          disabled={!userHasAccessToOnDemand}
          leftArea={{
            icon: {
              icon: 'house-circle-check',
            },
          }}
          padding="medium"
          text={t('onDemand.title', { ns: 'components' })}
          title={
            !userHasAccessToOnDemand ? t('onDemand.noAccess', { ns: 'components' }) : undefined
          }
          onClick={() => {
            InputHandler.instance.setTool(showAsbuiltPanel ? 'select' : 'on-demand');
          }}
        />
      )}
      {showAsbuiltChip && (
        <Chip
          leftArea={{
            icon: {
              icon: 'chart-network',
            },
          }}
          padding="medium"
          text="As-built"
          onClick={() => {
            InputHandler.instance.setTool(showAsbuiltPanel ? 'select' : 'asbuilt-geometry');
          }}
        />
      )}
      {showShareSkyViewPanel && <ShareView />}
      {showAsbuiltPanel && <AsbuiltPanel skyView={props.skyView} />}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 1em;
  gap: 1.5em;

  ${ChipStyled} {
    font-size: 14px;
  }
`;

SkyviewChipsContainer.propTypes = {
  wrapWithLanguageProvider: PropTypes.bool,
  skyView: PropTypes.object,
};

export { SkyviewChipsContainer };
