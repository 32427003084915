import { useMutation } from '@tanstack/react-query';
import * as d3 from 'd3';
import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';

import {
  GeodataMarker,
  PutMarkerParameters,
} from '../../../../../typings/api/skymap/rest/v0/geodata';
import { iiafe } from '../../../../../utilities/async';
import { SkyMapAxiosServiceFactory } from '../../../../js/services/axios/skymap-axios-service-factory';
import { t } from '../../../../js/utils/i18-next';
import { isDefined } from '../../../../js/utils/variables';
import { useButtonHotkey } from '../../../hooks/use-button-hotkey';
import { Center } from '../../../styles/center';
import { Button } from '../../button/button';
import { Stack } from '../../stack/stack';
import { GeodataContext } from '../geodata-state';
import { sfmViewerLayering } from './layering';
import { PlaceMarkersContext } from './place-markers-state';

function getDefaultTransformation(
  imgWidth: number,
  imgHeight: number,
  existingMarker: GeodataMarker | undefined,
  zoomRef: ZoomRef | undefined,
  svg: SVGSVGElement | null,
) {
  if (isDefined(svg) && isDefined(zoomRef) && isDefined(existingMarker)) {
    const gcpChanged = existingMarker.gcpId !== zoomRef.gcpId;
    const imageChanged = existingMarker.imageId !== zoomRef.imageId;

    // If neither the GCP or image changed. Then keep the current zoom.
    if (!gcpChanged && !imageChanged) {
      return zoomRef.transform;
    }

    const [normalizedX, normalizedY] = existingMarker.position;
    const x = normalizedX * imgWidth;
    const y = normalizedY * imgHeight;

    const scale = zoomRef.transform.k;
    const svgWidth = svg.clientWidth;
    const svgHeight = svg.clientHeight;

    // Compute translation to center the marker.
    const translateX = -x * scale + svgWidth / 2;
    const translateY = -y * scale + svgHeight / 2;

    return d3.zoomIdentity.translate(translateX, translateY).scale(scale);
  }

  // Reset zoom if there is no marker to zoom on.
  return d3.zoomIdentity;
}

function getMarkerGroup(
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  position: [number, number],
  color: string,
  size: number,
  strokeWidth: number,
  label?: string,
) {
  const [factorX, factorY] = position;

  // Create a group for each marker and position it based on factorX and factorY.
  const markerGroup = svg.append('g');

  // Draw the "X" marker centered at (0,0) within the group.
  markerGroup
    .append('line')
    .attr('x1', -size)
    .attr('y1', -size)
    .attr('x2', size)
    .attr('y2', size)
    .attr('stroke', color)
    .attr('stroke-width', strokeWidth);

  markerGroup
    .append('line')
    .attr('x1', -size)
    .attr('y1', size)
    .attr('x2', size)
    .attr('y2', -size)
    .attr('stroke', color)
    .attr('stroke-width', strokeWidth);

  // Center yellow dot with a black border
  markerGroup
    .append('circle')
    .attr('r', 5)
    .attr('fill', color)
    .attr('stroke', 'black')
    .attr('stroke-width', 1)
    .attr('cx', 0)
    .attr('cy', 0);

  // Add label to the bottom right of the dot if provided
  if (label) {
    // Append a rect behind the text for background
    const textPadding = 2;
    const text = markerGroup
      .append('text')
      .attr('x', 10)
      .attr('y', 15)
      .attr('fill', 'black')
      .attr('font-size', '1em')
      .text(label);

    const bbox = text.node()!.getBBox();
    markerGroup
      .insert('rect', 'text') // Insert rect before text
      .attr('x', bbox.x - textPadding)
      .attr('y', bbox.y - textPadding)
      .attr('width', bbox.width + 2 * textPadding)
      .attr('height', bbox.height + 2 * textPadding)
      .attr('fill', 'rgba(255, 255, 255, 0.3)') // Transparent white background
      .attr('rx', 3) // Optional rounded corners
      .attr('ry', 3);
  }

  // Store each marker's data and group element in the markersLayers array.
  return { factorX, factorY, element: markerGroup };
}

function getMouseGroup(
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  size: number,
  strokeWidth: number,
  label?: string,
) {
  // Create a group for the mouse circle and add the circle element inside.
  const mouseCircleGroup = svg.append('g').style('display', 'none');

  const radius = size * 1.5;
  mouseCircleGroup
    .append('circle')
    .attr('r', radius)
    .attr('fill', 'none')
    .attr('stroke', 'white')
    .attr('stroke-width', strokeWidth);

  // Four black lines inside the circle (not reaching the center)
  const lineStart = radius * 0.2; // 20% of the circle radius
  const lineEnd = radius * 0.8; // 20% of the circle radius

  // Vertical line (top)
  mouseCircleGroup
    .append('line')
    .attr('x1', 0)
    .attr('y1', -lineStart)
    .attr('x2', 0)
    .attr('y2', -lineEnd)
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth / 2);

  // Vertical line (bottom)
  mouseCircleGroup
    .append('line')
    .attr('x1', 0)
    .attr('y1', lineStart)
    .attr('x2', 0)
    .attr('y2', lineEnd)
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth / 2);

  // Horizontal line (left).
  mouseCircleGroup
    .append('line')
    .attr('x1', -lineStart)
    .attr('y1', 0)
    .attr('x2', -lineEnd)
    .attr('y2', 0)
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth / 2);

  // Horizontal line (right).
  mouseCircleGroup
    .append('line')
    .attr('x1', lineStart)
    .attr('y1', 0)
    .attr('x2', lineEnd)
    .attr('y2', 0)
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth / 2);

  // Center yellow dot with a black border.
  mouseCircleGroup
    .append('circle')
    .attr('r', 4)
    .attr('fill', 'yellow')
    .attr('stroke', 'black')
    .attr('stroke-width', 1)
    .attr('cx', 0)
    .attr('cy', 0);

  // Add label to the bottom right of the dot if provided
  if (label) {
    // Append a rect behind the text for background
    const textPadding = 2;
    const text = mouseCircleGroup
      .append('text')
      .attr('x', radius * 1.1)
      .attr('y', 0)
      .attr('fill', 'black')
      .attr('font-size', '1em')
      .text(label);

    const bbox = text.node()!.getBBox();
    mouseCircleGroup
      .insert('rect', 'text') // Insert rect before text
      .attr('x', bbox.x - textPadding)
      .attr('y', bbox.y - textPadding)
      .attr('width', bbox.width + 2 * textPadding)
      .attr('height', bbox.height + 2 * textPadding)
      .attr('fill', 'rgba(255, 255, 255, 0.3)') // Transparent white background
      .attr('rx', 3) // Optional rounded corners
      .attr('ry', 3);
  }

  return mouseCircleGroup;
}

function usePutMarkerMutation() {
  const mutation = useMutation({
    mutationFn: (params: PutMarkerParameters) => {
      return SkyMapAxiosServiceFactory.instance.createGeodataServiceV0().putMarker(params);
    },
    retry: false,
    networkMode: 'always',
  });

  return {
    putMarker: mutation.mutateAsync,
    isPending: mutation.isPending,
  };
}

type ZoomRef = {
  transform: d3.ZoomTransform;
  gcpId?: string;
  imageId?: string;
};

export const PlaceMarkerViewer = () => {
  const { putMarker } = usePutMarkerMutation();
  const { signer, geodata } = useContext(GeodataContext);
  const { gcp, image, showPlaceMarkersDialog, setShowPlaceMarkersDialog, updateMarker } =
    useContext(PlaceMarkersContext);

  const svgRef = useRef<SVGSVGElement>(null);
  const closeButtonRef = useRef(null);
  const zoomTransformRef = useRef<ZoomRef>({ transform: d3.zoomIdentity });

  useButtonHotkey(closeButtonRef, 'Escape');

  useEffect(() => {
    if (!isDefined(signer) || !isDefined(image) || !isDefined(svgRef.current)) {
      return;
    }

    const imgElement = new Image();

    if (isDefined(image?.file)) {
      // Handle the File case.
      const reader = new FileReader();
      reader.onload = () => {
        imgElement.src = reader.result as string;
      };
      reader.readAsDataURL(image.file);
    } else {
      imgElement.src = signer.getSignedRequest(`previews/${image.imageIdentifier}`);
    }

    const existingMarker = isDefined(gcp)
      ? image?.markers.find((x) => x.gcpId === gcp.id)
      : undefined;

    const handleMarkerUpdate = (pinned: boolean, position: [number, number]) => {
      if (!isDefined(gcp?.id) || !isDefined(image.imageId)) {
        return;
      }

      const params: PutMarkerParameters = {
        path: {
          geodataId: geodata.id,
          markerId: existingMarker?.id ?? v4(),
        },
        body: {
          gcpId: gcp.id,
          imageId: image.imageId,
          pinned: pinned,
          position: position,
        },
      };

      iiafe(async () => {
        const result = await putMarker(params);
        updateMarker(image, result.data);
      });
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === ' ' && isDefined(existingMarker)) {
        handleMarkerUpdate(!existingMarker.pinned, existingMarker.position);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    imgElement.onload = () => {
      const svg = d3.select<SVGSVGElement, unknown>(svgRef.current!);

      // Sets the svg so it is always the same width to avoid issues with inconsistent scaling.
      const imgWidth = 2000;
      const imgHeight = imgElement.naturalHeight * (imgWidth / imgElement.naturalWidth);

      svg
        .attr('width', '100%')
        .attr('height', '100%')
        .style('cursor', 'none')
        .attr('viewBox', `0 0 ${imgWidth} ${imgHeight}`)
        .style('display', 'block');

      svg.selectAll('*').remove();

      const imageLayer = svg.append('g');
      const markersLayers: {
        factorX: number;
        factorY: number;
        element: d3.Selection<SVGGElement, unknown, null, undefined>;
      }[] = [];

      imageLayer
        .append('image')
        .attr('href', imgElement.src)
        .attr('width', imgWidth)
        .attr('height', imgHeight);

      const size = imgWidth * 0.015;
      const strokeWidth = imgWidth * 0.0012;

      for (const marker of image?.markers ?? []) {
        const matchingGcp = geodata.gcpCollection?.points.find((x) => x.id === marker.gcpId);

        const color = marker.pinned ? 'green' : 'blue';

        markersLayers.push(
          getMarkerGroup(svg, marker.position, color, size, strokeWidth, matchingGcp?.name),
        );

        if (
          isDefined(marker.reprojectionError) &&
          Math.abs(marker.reprojectionError[0] + marker.reprojectionError[1]) > 0
        ) {
          markersLayers.push(
            getMarkerGroup(
              svg,
              [
                marker.position[0] + marker.reprojectionError[0],
                marker.position[1] + marker.reprojectionError[1],
              ],
              'black',
              size * 0.75,
              strokeWidth,
              matchingGcp?.name,
            ),
          );
        }
      }

      const mouseCircleGroup = getMouseGroup(svg, size, strokeWidth, gcp?.name);

      let mouseFactorX = 0,
        mouseFactorY = 0;

      const getMouseRelativePosition = (event: MouseEvent) => {
        const [x, y] = d3.pointer(event);
        // Get the current zoom transform to adjust for zoom and pan.
        const transform = d3.zoomTransform(imageLayer.node()!);

        // Calculate mouse position relative to the transformed image.
        const relativeX = (x - transform.x) / transform.k;
        const relativeY = (y - transform.y) / transform.k;

        // Calculate mouse factors based on the original image dimensions.
        return {
          x,
          y,
          relativeX: relativeX / imgWidth,
          relativeY: relativeY / imgHeight,
        };
      };

      // Update relative mouse position and show the circle.
      svg.on('mousemove', (event: MouseEvent) => {
        const mouse = getMouseRelativePosition(event);

        // Calculate mouse factors based on the original image dimensions.
        mouseFactorX = mouse.relativeX;
        mouseFactorY = mouse.relativeY;

        mouseCircleGroup
          .attr('transform', `translate(${mouse.x}, ${mouse.y})`)
          .style('display', 'block');
      });

      svg.on('mouseleave', () => {
        mouseCircleGroup.style('display', 'none');
      });

      let lastMouseDown = Date.now();

      svg.on('pointerdown', (event: MouseEvent) => {
        lastMouseDown = Date.now();
      });

      svg.on('contextmenu', (event) => {
        if (!event.shiftKey) {
          event.preventDefault();
        }
      });

      svg.on('pointerup', (event: MouseEvent) => {
        const elapsedTimeMs = Date.now() - lastMouseDown;
        const isClick = elapsedTimeMs < 250;
        const isLeftMouseButton = event.button === 0;
        const isRightMouseButton = event.button === 2;

        if (!isClick || !(isLeftMouseButton || isRightMouseButton)) {
          return;
        }

        // Don't do anything on right click if marker does not exist.
        if (isRightMouseButton && !isDefined(existingMarker)) {
          return;
        }

        const defaultPosition = existingMarker?.position ?? [0.5, 0.5];

        const mouse = getMouseRelativePosition(event);
        handleMarkerUpdate(
          isLeftMouseButton,
          !isLeftMouseButton ? defaultPosition : [mouse.relativeX, mouse.relativeY],
        );
      });

      const zoom = d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([0.8, 1000])
        .on('zoom', (event: d3.D3ZoomEvent<SVGSVGElement, unknown>) => {
          const transform = event.transform;
          imageLayer.attr('transform', transform.toString());

          // Update the zoomTransformRef.
          if (isDefined(existingMarker)) {
            zoomTransformRef.current = {
              transform,
              gcpId: existingMarker.gcpId,
              imageId: existingMarker.imageId,
            };
          } else {
            zoomTransformRef.current = { transform: zoomTransformRef.current.transform };
          }

          // Update each marker layer's position based on its relative coordinates.
          markersLayers.forEach(({ factorX, factorY, element }) => {
            const x = factorX * imgWidth * transform.k + transform.x;
            const y = factorY * imgHeight * transform.k + transform.y;
            element.attr('transform', `translate(${x}, ${y})`);
          });

          // Update the cursor position based on its relative coordinates to the image.
          const mouseX = mouseFactorX * imgWidth * transform.k + transform.x;
          const mouseY = mouseFactorY * imgHeight * transform.k + transform.y;
          mouseCircleGroup.attr('transform', `translate(${mouseX}, ${mouseY})`);
        });

      svg.call(zoom);

      // Apply zoom identity once in order for zoom callback to be called once and init the state.
      svg.call(() =>
        zoom.transform(
          svg,
          getDefaultTransformation(
            imgWidth,
            imgHeight,
            existingMarker,
            zoomTransformRef.current,
            svgRef.current,
          ),
        ),
      );
    };

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [signer, image, gcp, svgRef, putMarker, geodata, updateMarker]);

  if (!showPlaceMarkersDialog) {
    return null;
  }

  if (!isDefined(image)) {
    return <Center>No image selected</Center>;
  }

  return (
    <PlaceMarkerContainer>
      <Content>
        <svg ref={svgRef} />

        <QuickButtonsContainer spacing={0.5}>
          <Stack direction="row" spacing={0.2}>
            <Button
              color="secondary"
              leftIcon={{ icon: ['fal', 'portal-exit'] }}
              ref={closeButtonRef}
              variant="contained"
              onClick={() => setShowPlaceMarkersDialog(false)}
            >
              {t('close', { ns: 'common' })}
            </Button>

            <Button
              color="secondary"
              leftIcon={{ icon: ['fal', 'arrow-up'] }}
              variant="contained"
              onClick={() => setShowPlaceMarkersDialog(false)}
            >
              {t('next', { ns: 'common' })}
            </Button>

            <Button
              color="secondary"
              leftIcon={{ icon: ['fal', 'arrow-down'] }}
              variant="contained"
              onClick={() => setShowPlaceMarkersDialog(false)}
            >
              {t('previous', { ns: 'common' })}
            </Button>
          </Stack>

          <Label>IMG: {image?.displayName ?? 'N/A'}</Label>
          <Label>GCP: {gcp?.name ?? 'N/A'}</Label>
        </QuickButtonsContainer>
      </Content>
    </PlaceMarkerContainer>
  );
};

const QuickButtonsContainer = styled(Stack)`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: ${sfmViewerLayering.placeMarkersContainerMenu};
`;

const Label = styled.label`
  color: white;
  font-weight: bold;
  font-size: 1.5em;
`;

const PlaceMarkerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.placeMarkersContainer};
`;

const Content = styled.div`
  background-color: black;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
