import type * as CSS from 'csstype';
import React, { FocusEvent, InputHTMLAttributes, MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { isDefined } from '../../../js/utils/variables';
import { nameof } from '../../utils/nameof';
import { reset } from '../../utils/styled-reset';
import { InfoBox } from '../info-box/info-box';

interface Props {
  defaultValue?: string;
  value?: string;
  errorMessage?: string;
  disabled?: boolean;
  readOnly?: boolean;
  readOnlyCursor?: CSS.Properties['cursor'];
  width?: number;
  placeholder?: string;
  /**
   * Whether to display placeholder as long as the text matches it (case sensitive)
   */
  placeholderVisibleOnMatch?: boolean;
  maxLength?: InputHTMLAttributes<HTMLInputElement>['maxLength'];
  maxVisibleChars?: number;
  monospaced?: 'montserrat' | 'roboto';
  step?: number;
  min?: InputHTMLAttributes<HTMLInputElement>['min'];
  max?: InputHTMLAttributes<HTMLInputElement>['max'];
  id?: string;
  type?: 'text' | 'number' | 'password';
  /**
   * @default 0.5em
   */
  padding?: number;
  hideStepper?: boolean;
  autoFocus?: boolean;
  enforceCase?: 'lowercase' | 'uppercase';
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onClick?: (e: MouseEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
}

const TextBox = React.forwardRef<HTMLInputElement, Props>(
  ({ padding = 0.5, type = 'text', ...props }, ref) => {
    const isControlled = props.value !== undefined;
    const enforceTextCase = (
      text: string,
      type: 'text' | 'number' | 'password' = 'text',
      enforceCase?: 'lowercase' | 'uppercase',
    ) => {
      if (type !== 'text' || !isDefined(enforceCase)) {
        return text;
      }
      return enforceCase === 'lowercase' ? text.toLowerCase() : text.toUpperCase();
    };

    const onFocus = (e: FocusEvent<HTMLInputElement, Element>) => e.target.select();
    const [uncontrolledValue, setUncontrolledValue] = React.useState(() => {
      return enforceTextCase(props.defaultValue ?? '', type, props.enforceCase);
    });

    const enforcedValue = enforceTextCase(
      isControlled ? props.value ?? '' : uncontrolledValue,
      type,
      props.enforceCase,
    );

    const [textIsOverflowing, setTextIsOverflowing] = React.useState(false);

    // Update `uncontrolledValue` when `enforceCase` changes for uncontrolled components
    React.useEffect(() => {
      if (!isControlled) {
        setUncontrolledValue((prevValue) => enforceTextCase(prevValue, type, props.enforceCase));
      }
    }, [props.enforceCase, type, isControlled]);

    // Display placeholder textbox component if placeholder is set and text matches placeholder text.
    // Don't display textbox if entered text in user textbox overflows with of the textbox itself.
    const displayPlaceholderTextBox =
      props.placeholder &&
      props.placeholderVisibleOnMatch &&
      props.placeholder?.startsWith(enforcedValue ?? '') &&
      !textIsOverflowing;

    const valueChangeHandler = (
      e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>,
      callback?: (e: any) => void,
    ) => {
      const target = e.currentTarget;
      const newValue = enforceTextCase(target.value, type, props.enforceCase);

      setTextIsOverflowing(target.scrollWidth > target.clientWidth);

      if (!isControlled) {
        setUncontrolledValue(newValue);
      }

      if (type === 'text' && isDefined(props.enforceCase)) {
        target.value = newValue; // Update the event's value to the enforced case
      }
      callback?.(e);
    };
    return (
      <Component maxVisibleChars={props.maxVisibleChars} width={props.width}>
        {/**
         * In order to continue to display a placeholder while typing, we need to create another
         * textbox with the placeholder set (readonly), and display it below the ordinary textbox
         * (with a transparent background).
         *
         * The first textbox is rendered only when users text matches the start of the placeholder
         * text, and placeholderVisibleOnMatch is true. Otherwise the component won't be rendered and
         * placeholder is not visible.
         *
         * If displayPlaceholderTextBox is false, we don't need to render this textbox as the ordinary
         * textbox also has the placeholder (that will be hidden while typing).
         *
         * Set role to presentation to remove implicit ARIA semantics. Then tests won't pick it up
         * with screen.getByRole('textbox') for instance.
         */}
        {displayPlaceholderTextBox && (
          <PlaceHolderTextBox
            className="notranslate" // Disable Google translation.
            monospaced={props.monospaced}
            placeholder={props.placeholder}
            readOnly={true}
            role="presentation"
            tabIndex={-1}
          />
        )}

        <UserTextBox
          {...props}
          aria-autocomplete="none"
          aria-invalid={isDefined(props.errorMessage)}
          autoComplete="off"
          autoFocus={props.autoFocus ?? false}
          className="notranslate" // Disable Google translation.
          defaultValue={!isControlled ? enforcedValue : undefined}
          disabled={props.disabled}
          hideStepper={props.hideStepper}
          id={props.id}
          max={props.max}
          maxLength={props.maxLength}
          min={props.min}
          monospaced={props.monospaced}
          padding={padding}
          placeholder={props.placeholder}
          ref={ref}
          step={props.step}
          type={type}
          value={isControlled ? enforcedValue : undefined}
          onBlur={props.onBlur}
          onChange={(e) => valueChangeHandler(e, props.onChange)}
          onClick={props.onClick}
          onDoubleClick={(e) => e.stopPropagation()}
          onFocus={(e) => {
            if (isDefined(props.onFocus)) {
              props.onFocus(e);
            } else if (isDefined(props.autoFocus)) {
              onFocus(e);
            }
          }}
          onInput={(e) => valueChangeHandler(e, props.onInput)}
          onKeyDown={props.onKeyDown}
          onPaste={props.onPaste}
        />
        {props.errorMessage && (
          <ErrorMessage color="red" fontSize="small" padding="small">
            {props.errorMessage}
          </ErrorMessage>
        )}
      </Component>
    );
  },
);

TextBox.displayName = nameof({ TextBox });

const Component = styled.div<Pick<Props, 'width' | 'maxVisibleChars'>>`
  position: relative;
  background-color: #fff !important;

  // Use in order:
  // 1. maxVisibleChars (min-width).
  // 2. width to set width to amount of pixels.
  // 3. otherwise set width to 100%.
  ${(props) =>
    props.maxVisibleChars
      ? css`
          max-width: ${props.maxVisibleChars}ch;
          width: 100%;
          box-sizing: content-box;
        `
      : props.width
        ? css`
            width: ${props.width}px;
          `
        : css`
            width: 100%;
          `};
`;

const TextBoxStyle = styled.input<
  Pick<Props, 'errorMessage' | 'readOnlyCursor' | 'hideStepper' | 'padding' | 'monospaced'>
>`
  && {
    ${reset}
    font-size: 14px;
    padding: ${(props) => props.padding ?? 0.5}em;
    border: 1px solid ${(props) => (isDefined(props.errorMessage) ? '#b22222' : '#888')};
    width: inherit;

    // Monospaced?
    ${(props) =>
      props.monospaced === 'roboto' &&
      css`
        font-family: 'Roboto Mono';
        font-weight: 300;
      `}
    ${(props) =>
      props.monospaced === 'montserrat' &&
      css`
        font-feature-settings: 'tnum';
      `}

    ${(props) =>
      props.hideStepper &&
      css`
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield; /* Firefox */
      `}

    &:focus {
      outline: none;
    }

    &:read-only {
      background-color: inherit;

      ${(props) =>
        props.readOnlyCursor &&
        css`
          cursor: ${props.readOnlyCursor};
        `}
    }
  }
`;

const PlaceHolderTextBox = styled(TextBoxStyle)`
  position: absolute !important;
  top: 0;
  left: 0;
  border-color: transparent !important;
`;

const UserTextBox = styled(TextBoxStyle)`
  position: relative !important;
  background-color: transparent !important;

  ${(props) =>
    props.disabled &&
    css`
      background-color: #fafafa !important;
    `}
`;

const ErrorMessage = styled(InfoBox)``;

export { TextBox, UserTextBox as TextBoxStyled };
