import type * as CSS from 'csstype';
import React, { MouseEvent, RefObject } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { useButtonHotkey } from '../../hooks/use-button-hotkey';
import { useFloatingPosition } from '../../hooks/use-floating-position';
import { reset } from '../../utils/styled-reset';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';

type Position =
  | 'center'
  | {
      top: number;
      left: number;
    };

type Props = {
  /**
   * @default false
   */
  disableDimmer?: boolean;
  /**
   * @default false
   */
  closeOnDimmerClick?: boolean;
  onClose: () => Promise<void> | void;
  /**
   * @default center
   */
  position?: Position;
  maxWidth?: number | CSS.Properties['width'];
  width?: number | CSS.Properties['width'];
  height?: number | string;
  /**
   * Determines max height behavrious
   * @default true
   */
  maxHeight?: boolean | 'expand';
  contentPadding?: boolean;
  closeIcon?: boolean;
  useGoogleTranslate?: boolean;
  closeButtonRef?: RefObject<HTMLButtonElement>;
  confirmButtonRef?: RefObject<HTMLButtonElement>;
  children: {
    header: React.ReactNode;
    content: React.ReactNode;
    /**
     * Content in footer can be placed to the left and to the right if specified. Otherwise it
     * renders the component as is. If left and right properties are used, content is per default
     * stacked horizontally. If you want to suppress this, wrap the content with another element
     * (<div>...</div> for instance).
     */
    footer?: { left?: React.ReactNode; right?: React.ReactNode } | React.ReactNode;
  };
  context?: (children: React.ReactNode) => React.ReactNode;
};

const Dialog = ({
  disableDimmer = false,
  closeOnDimmerClick = false,
  maxHeight = true,
  position = 'center',
  context = (children) => <>{children}</>,
  ...props
}: Props) => {
  const { ref, floatingStyles, floatingProps } = useFloatingPosition({
    followMousePointer: false,
    middleware: 'shift',
    offset: {
      mousePointer: 10,
      viewportEdge: 5,
    },
    startPos:
      typeof position === 'string' || !position
        ? undefined
        : {
            x: position.left,
            y: position.top,
          },
    strategy: 'fixed',
  });

  useButtonHotkey(props.closeButtonRef, 'Escape', { bubble: true });
  useButtonHotkey(props.confirmButtonRef, 'Enter');

  const onDimmerClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (closeOnDimmerClick) {
      (props.onClose as () => void)();
    }
  };

  const renderFooter = (footer: NonNullable<Props['children']['footer']>) => {
    const leftStack =
      typeof footer === 'object' && 'left' in footer && footer.left ? (
        <Stack direction="row" spacing={0.5}>
          {footer.left}
        </Stack>
      ) : undefined;
    const rightStack =
      typeof footer === 'object' && 'right' in footer && footer.right ? (
        <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
          {footer.right}
        </Stack>
      ) : undefined;

    return leftStack && rightStack ? (
      <Stack direction="row" justifyContent="space-between" spacing={0.5}>
        {leftStack}
        {rightStack}
      </Stack>
    ) : (
      leftStack ?? rightStack ?? (footer as React.ReactNode)
    );
  };

  const isCentered = typeof position === 'string';

  const component = context(
    <>
      {!disableDimmer && <Dimmer onClick={onDimmerClick} />}
      <Component
        onClick={(e) => e.stopPropagation()}
        {...props}
        className={props.useGoogleTranslate ? undefined : 'notranslate'}
        data-testid="dialog"
        maxHeight={maxHeight}
        position={position}
        // Apply Floating-UI specific things only when dialog is explicitly set to a fixed position.
        ref={!isCentered ? ref : undefined}
        style={!isCentered ? floatingStyles : undefined}
        {...(!isCentered ? floatingProps : [])}
      >
        <Header>
          <Stack alignItems="baseline" direction="row" justifyContent="space-between" spacing={1}>
            <span>{props.children.header}</span>
            {props.closeIcon && <Icon icon="xmark" onClick={props.onClose} />}
          </Stack>
        </Header>
        <Content padding={props.contentPadding ?? true}>{props.children.content}</Content>
        {props.children.footer && <Footer>{renderFooter(props.children.footer)}</Footer>}
      </Component>
    </>,
  );

  return ReactDOM.createPortal(component, document.body);
};

//
// Same z-index to dimmer and dialog.
// Dimmer is before in markup so it will be rendered first, then dialog rendered above.
// Displaying multiple dialogs works as well because of the order they are put in the DOM.
//

const Dimmer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
`;

const Component = styled.div<
  Pick<Props, 'width' | 'maxWidth' | 'height' | 'maxHeight'> & Required<Pick<Props, 'position'>>
>`
  ${reset}
  --padding: 1em;

  position: fixed;
  font-size: 14px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0 #00000060;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;

  ${({ width }) =>
    typeof width === 'number'
      ? css`
          width: clamp(0px, ${width}px, 90%);
        `
      : css`
          width: ${width};
        `}

  ${({ maxWidth }) =>
    typeof maxWidth === 'number'
      ? css`
          max-width: clamp(0px, ${maxWidth}px, 90%);
        `
      : css`
          max-width: ${maxWidth};
        `}

  ${({ height, maxHeight }) =>
    maxHeight === 'expand'
      ? css`
          max-height: calc(100% - 2em);
        `
      : height
        ? css`
            height: ${typeof height === 'string' ? height : `${height}px`};
          `
        : maxHeight
          ? css`
              max-height: 60vh;
            `
          : undefined}

  ${({ position }) =>
    position === 'center'
      ? css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : undefined}
`;

const Header = styled.header`
  padding: var(--padding);
  background-color: #eee;

  span {
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Content = styled.div<{ padding: boolean }>`
  // Margin top instead of padding top in order to be able
  // to place components inside that has sticky with top:0,
  // so overflowed content in component does not overflow
  // outside (above) the sticky element.
  //
  // However, this leaves a space above the scrollbar with
  // height equal to margin-top.
  //
  // todo: Check if we can use padding for all sides and
  // solve this problem using another method.
  ${(props) =>
    props.padding &&
    css`
      margin-top: var(--padding);
      padding: 0 var(--padding) var(--padding) var(--padding);
    `}

  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.footer`
  padding: var(--padding);
  background-color: #eee;
`;

export { Dialog };
