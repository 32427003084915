import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { Vector3 } from 'three';

import { GcpModel } from '../../../../../typings/api/skymap/rest/v0/.common';
import { isDefined } from '../../../../js/utils/variables';
import { DataTable, DataTableSortType } from '../../data-table/data-table';
import { IconPanel } from '../../icon-panel/icon-panel';
import { OverlayLoader } from '../../overlay-loader/overlay-loader';
import { Stack } from '../../stack/stack';
import { TextBox } from '../../text-box/text-box';
import { GeodataContext } from '../geodata-state';
import {
  GeodataImageItemWithMarkers,
  GeodataMarkerStatusIcon,
  PlaceMarkersContext,
} from './place-markers-state';

const pageSize = 15;
type Item = {
  id: string;
  status: 'pinned' | 'unpinned';
  statusIcon: ReactNode;
  imageName: string;
  image: GeodataImageItemWithMarkers;
  gcpName: string;
  gcp: GcpModel;
  residualError: number;
};

const searchKeys = ['gcpName', 'imageName', 'status'] as const;

function filterItems(items: Item[], searchText: string): Item[] {
  const text = searchText.trim().toLowerCase();
  if (text.length === 0) {
    return items;
  }

  return items.filter((item) =>
    searchKeys.some((x) => item[x]?.toLocaleLowerCase().includes(text)),
  );
}

export const NoMarkersPanel = () => {
  const { t } = useTranslation();

  return (
    <NoGcpPanelStyle>
      <IconPanel
        header={{ icon: { icon: ['fad', 'magnifying-glass'], opacity: '50%', size: '4x' } }}
        responsiveness={{ compressed: false }}
      >
        {{
          title: t('markerList.noMarkers', { ns: 'cloudProcessing' }),
          info: t('markerList.noMarkersInfo', { ns: 'cloudProcessing' }),
        }}
      </IconPanel>
    </NoGcpPanelStyle>
  );
};

const NoGcpPanelStyle = styled.div`
  display: flex;
  padding: 1em;
`;

export const MarkerList = () => {
  const { isGeodataProcessing, geodata } = useContext(GeodataContext);
  const { gcp, image, selectImage, selectGcp, imagesWithMarkers, setShowPlaceMarkersDialog } =
    useContext(PlaceMarkersContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const [items, setItems] = useState<Item[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);

  const renderNoImagesPanel = items.length === 0;

  useEffect(() => {
    if (isDefined(geodata.gcpCollection?.points)) {
      const newItems: Item[] = imagesWithMarkers.flatMap((image) => {
        return image.markers.map((marker) => {
          const gcp = geodata.gcpCollection?.points.find((x) => x.id === marker.gcpId);
          return {
            id: marker.gcpId,
            gcp: gcp!,
            gcpName: gcp!.name,
            image: image,
            imageName: image.displayName,
            status: marker.pinned ? 'pinned' : 'unpinned',
            residualError: isDefined(marker.residualError)
              ? new Vector3(...marker.residualError).length()
              : 0,
            statusIcon: GeodataMarkerStatusIcon(marker.pinned ? 'PINNED' : 'ESTIMATED', theme),
          } satisfies Item;
        });
      });

      setItems(newItems);
    } else {
      setItems([]);
    }
  }, [geodata, theme, imagesWithMarkers]);

  useEffect(() => {
    setFilteredItems(filterItems(items, searchText));
  }, [items, searchText]);

  return (
    <OverlayLoader visible={isGeodataProcessing}>
      <Stack spacing={1}>
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <TextBox
            placeholder={t('search', { ns: 'common' })}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Stack>

        {renderNoImagesPanel ? (
          <NoMarkersPanel />
        ) : (
          <DataTable
            columns={{
              statusIcon: {
                title: 'Status',
                alignment: 'center',
                width: '45px',
                sortableBy: { type: DataTableSortType.STRING, columnKey: 'status' },
                unfilterable: true,
              },
              imageName: {
                sortableBy: { type: DataTableSortType.STRING },
                unfilterable: true,
                title: 'Bild',
              },
              gcpName: {
                sortableBy: { type: DataTableSortType.STRING },
                unfilterable: true,
                title: 'GCP',
              },
              residualError: {
                sortableBy: { type: DataTableSortType.NUMBER },
                unfilterable: true,
                title: 'Error (m)',
                formatter: (value) => value.toFixed(3),
              },
            }}
            fixedLayout={true}
            highlightRow={(item) =>
              item.gcp.id === gcp?.id && item.image.imageId === image?.imageId
            }
            items={filteredItems}
            pageSize={pageSize}
            onRowClicked={(item) => {
              selectGcp(item.gcp);
              selectImage(item.image);
              setShowPlaceMarkersDialog(true);
            }}
          />
        )}
      </Stack>
    </OverlayLoader>
  );
};
