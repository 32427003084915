import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { OrthographicCamera, Vector3 } from 'three';

import { GeodataStore } from '../../../../js/stores/geodata-store';
import { ProjectStore } from '../../../../js/stores/project-store';
import { isDefined } from '../../../../js/utils/variables';
import { CoordinateManager } from '../../../../js/viewer/coordinate-manager';
import { useObject3d } from '../../../hooks/three/use-object-3d';
import { useOrbitControls } from '../../../hooks/three/use-orbit-controls';
import { useWms } from '../../../hooks/three/use-wms';
import { useDialog } from '../../../hooks/use-dialog';
import { reset } from '../../../utils/styled-reset';
import { Button } from '../../button/button';
import { Dialog } from '../../dialog/dialog';
import { Stack } from '../../stack/stack';
import { GeodataConfiguration } from '../geodata-configuration/geodata-configuration';
import { GeodataImagesContext, GeodataImagesState } from '../geodata-images-state';
import { GeodataContext, GeodataState } from '../geodata-state';
import { sfmViewerLayering } from './layering';
import { PlaceMarkersContext, PlaceMarkersState } from './place-markers-state';
import { PlaceMarkerViewer } from './place-markers-viewer';
import { SfmViewerRightMenu } from './sfm-viewer-right-menu';
import { useCursor } from './use-cursor';
import { useGcpPoints } from './use-gcp-points';
import { useGeodataCameras } from './use-geodata-cameras';
import { useSfmRenderingContext } from './use-sfm-rendering-context';
import { useSparseReconstruction } from './use-sparse-reconstruction';

function useProjectProjection(origo?: Vector3) {
  return useMemo(() => {
    const proj4Definition = ProjectStore.instance.coordinateSystem?.proj4;
    if (!isDefined(proj4Definition)) {
      return undefined;
    }
    CoordinateManager.getInstance().setProjectProjectionData(
      proj4Definition,
      origo ?? new Vector3(),
    );
    return proj4Definition;
  }, [origo]);
}

/**
 * SfM viewer (Structure from motion).
 *
 * Works as an interface towards the SfM step in photogrammetry which is an intermediate state
 * where the camera poses and sparse reconstruction can be refined before moving on to dense
 * reconstruction.
 */
export const SfmViewer = () => {
  return (
    <GeodataState geodataId={GeodataStore.instance.geodata.id}>
      <GeodataImagesState>
        <PlaceMarkersState>
          <SfmViewerContent />
        </PlaceMarkersState>
      </GeodataImagesState>
    </GeodataState>
  );
};

const SfmViewerContent = () => {
  const webGlContainer = React.useRef<HTMLDivElement>(null);
  const css3dContainer = React.useRef<HTMLDivElement>(null);
  const wmsRenderTarget = useRef<HTMLDivElement>(null);
  const { geodata, signer } = useContext(GeodataContext);

  const settingsDialog = useDialog();
  const placeMarkersDialog = useDialog();

  const { webGlScene, css2dScene, wmsScene, camera, setCameraType } = useSfmRenderingContext(
    webGlContainer,
    css3dContainer,
  );

  const { images } = useContext(GeodataImagesContext);
  const { showPlaceMarkersDialog } = useContext(PlaceMarkersContext);
  const { cameras, updateGeodataCameras, origo } = useGeodataCameras();
  const projectProjection = useProjectProjection(origo);
  const { sparsePointCloud } = useSparseReconstruction(geodata, signer, origo);
  const { gcp } = useGcpPoints(origo);
  const [showSparsePoints, setShowSparsePoints] = useState(true);
  const controls = useOrbitControls(
    css3dContainer,
    camera,
    cameras?.points.geometry.boundingSphere?.center,
  );
  const { wmsQuad } = useWms(wmsRenderTarget, controls, projectProjection);

  const isOrthoView = useMemo(() => camera instanceof OrthographicCamera, [camera]);
  const quad = useMemo(() => (isOrthoView ? wmsQuad : undefined), [isOrthoView, wmsQuad]);

  useObject3d(wmsScene, quad);
  useObject3d(webGlScene, sparsePointCloud);
  useObject3d(webGlScene, gcp?.points);
  useObject3d(css2dScene, gcp?.labels);
  useObject3d(webGlScene, cameras?.points);

  useCursor(css3dContainer, css2dScene, webGlScene, camera, cameras?.points, gcp?.points);

  useEffect(() => {
    updateGeodataCameras(images);
  }, [images, updateGeodataCameras]);

  useEffect(() => {
    if (showPlaceMarkersDialog) {
      placeMarkersDialog.show();
    } else {
      placeMarkersDialog.hide();
    }
  }, [showPlaceMarkersDialog, placeMarkersDialog]);

  return (
    <Component>
      <StyledStack direction="row" spacing={0}>
        <Viewer>
          <WebGlCanvasContainer ref={webGlContainer} />
          <Css3dCanvasContainer ref={css3dContainer} />
          <WmsRenderTarget>
            <OlCanvas ref={wmsRenderTarget} />
          </WmsRenderTarget>

          <QuickButtonsContainer direction="row" spacing={0.2}>
            {isDefined(camera) && isDefined(controls) && isDefined(cameras?.points) && (
              <Button
                color="secondary"
                leftIcon={{ icon: ['fal', 'camera'] }}
                variant="contained"
                onClick={() => {
                  controls.target.copy(cameras.points.geometry.boundingSphere!.center);
                  camera.position.copy(controls.target.clone().add(new Vector3(0, 0, 100)));
                  controls.update();
                }}
              >
                {t('aligner.center', { ns: 'cloudProcessing' })}
              </Button>
            )}

            <Button
              color="secondary"
              leftIcon={{ icon: isOrthoView ? ['fad', 'image'] : ['fad', 'cube'] }}
              variant="contained"
              onClick={() => {
                setCameraType(isOrthoView ? 'perspective' : 'orthographic');
              }}
            >
              {t('aligner.view', { ns: 'cloudProcessing' })}
            </Button>

            {isDefined(sparsePointCloud) && (
              <Button
                color="secondary"
                leftIcon={{ icon: showSparsePoints ? ['fad', 'eye'] : ['fad', 'eye-slash'] }}
                title={t('aligner.previewInfo', { ns: 'cloudProcessing' })}
                variant="contained"
                onClick={() => {
                  setShowSparsePoints((oldState) => {
                    sparsePointCloud.visible = !oldState;
                    return !oldState;
                  });
                }}
              >
                {t('aligner.preview', { ns: 'cloudProcessing' })}
              </Button>
            )}

            <Button
              color="secondary"
              leftIcon={{ icon: ['fad', 'cog'] }}
              variant="contained"
              onClick={() => {
                settingsDialog.show();
              }}
            >
              {t('settings.title', { ns: 'cloudProcessing' })}
            </Button>
          </QuickButtonsContainer>

          {placeMarkersDialog.visible && (
            <PlaceMarkerContainer>
              <PlaceMarkerViewer />
            </PlaceMarkerContainer>
          )}
        </Viewer>

        <SfmViewerRightMenu />
      </StyledStack>
      {settingsDialog.render(<SettingsDialog dialog={settingsDialog} />)}
    </Component>
  );
};

const SettingsDialog = (props: { dialog: ReturnType<typeof useDialog> }) => {
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const [isPending, setIsPending] = useState(false);
  const context = useContext(GeodataContext);

  return (
    <Dialog
      closeIcon={true}
      context={(children) => (
        <GeodataContext.Provider value={context}>{children}</GeodataContext.Provider>
      )}
      maxHeight="expand"
      maxWidth={'90%'}
      onClose={props.dialog.hide}
    >
      {{
        header: t('settings.title', { ns: 'cloudProcessing' }),
        content: (
          <GeodataConfiguration
            saveSettingsButtonRef={confirmButtonRef}
            onDialogIsPendingChanged={(state) => setIsPending(state)}
            onSuccess={props.dialog.hide}
          />
        ),
        footer: {
          right: (
            <>
              <Button
                color="primary"
                disabled={isPending}
                ref={confirmButtonRef}
                variant="contained"
              >
                {t('confirm', { ns: 'common' })}
              </Button>
              <Button variant="text" onClick={props.dialog.hide}>
                {t('close', { ns: 'common' })}
              </Button>
            </>
          ),
        },
      }}
    </Dialog>
  );
};

const StyledStack = styled(Stack)`
  position: relative;
  height: 100%;
`;

const QuickButtonsContainer = styled(Stack)`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  z-index: ${sfmViewerLayering.menu};
`;

const Component = styled.div`
  ${reset}
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Viewer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WebGlCanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.webGlCanvasContainer};
`;

const PlaceMarkerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.placeMarkersContainer};
`;

const Css3dCanvasContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${sfmViewerLayering.css3dCanvasContainer};
`;

const WmsRenderTarget = styled.div`
  position: absolute;

  height: 2048px;
  width: 2048px;

  visibility: hidden;
`;

const OlCanvas = styled.div`
  position: absolute;

  height: 100%;
  width: 100%;
`;

SfmViewer.propTypes = {
  wrapWithLanguageProvider: PropTypes.any,
};
